<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
			<div class="center">
				<div class="ucenter_main">
					<ucMenu num="8" :visible = "reflashMenu"></ucMenu>
					<div class="right_con">
						<div class="rc_main" v-loading="isLoading">
							<p class="fb">岗位管理</p>
							<div class="select_wrap">
								<el-input class="w290" v-model="searchData.name" placeholder="请输入角色名称" clearable>
									<p slot="prefix" class="label_title">角色名称</p>
								</el-input>
								<el-select class="w290" v-model="searchData.status" placeholder="请选择状态" clearable filterable >
									<p slot="prefix" class="label_title">禁用状态</p>
									<el-option :value="1" label="启用">启用</el-option>
									<el-option :value="0" label="禁用">禁用</el-option>
								</el-select>
								<el-button plain size="small" @click="getList()">查询</el-button>
								<el-button plain size="small" @click="resetSearch()">重置</el-button>
							</div>
							<div class="totals_btn">
								<el-button plain type="primary" size="small" @click="positionOpen('1')">新增岗位</el-button>
								<el-button plain size="small" @click="positionDel">删除岗位</el-button>
							</div>
							<el-table class="rc_table" highlight-current-row @selection-change="selection"  row-key="role_id" :data="list" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
								<el-table-column align="center" type="selection" width="50"></el-table-column>
								<el-table-column align="center"  property="name" label="岗位名称" show-overflow-tooltip width=""></el-table-column>
								<el-table-column align="center"  property="description" label="岗位描述" show-overflow-tooltip width=""></el-table-column>
								<el-table-column align="center"  property="scope" label="状态" show-overflow-tooltip width="">
									<template slot-scope="scope">
										<el-switch :active-value="1" :inactive-value="0" v-model="scope.row.status" @change="positionStatus(scope.row)"></el-switch>
										<span style="margin-left:6px">{{scope.row.status=='1'?'启用':'禁用'}}</span>
									</template>
								</el-table-column>
								<!-- <el-table-column align="center"  property="access" label="权限" show-overflow-tooltip width=""></el-table-column> -->
								<el-table-column label="操作" align="center" width="180">
									<template slot-scope="scope">
										<el-button size="mini" type="primary" @click="positionOpen('2',scope.row.role_id)" >修改</el-button>
										<el-button size="mini" @click="positionDel(scope.row)" >删除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<Pagination :total="page_data.total" :page.sync="page_data.page" :limit.sync="page_data.limit" @pagination="getList" v-if="page_data.total>page_data.limit" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<myFoot></myFoot>
		<!-- 新增/修改岗位 -->
		<el-dialog :title="positionData.title" :visible.sync="positionData.isShow" width="30%" @close='closeMask'>
			<el-form label-width="80px" :model="positionData.data">
				<el-form-item label="岗位名称">
					<el-input size="small" v-model="positionData.data.name" placeholder="请输入岗位名称"></el-input>
				</el-form-item>
				<el-form-item label="岗位状态">
					<el-switch v-model="positionData.data.status" :active-value="1" :inactive-value="0" active-color="#13ce66"></el-switch>
				</el-form-item>
				<el-form-item label="岗位描述">
					<el-input size="small" v-model="positionData.data.description" placeholder="请输入岗位描述"></el-input>
				</el-form-item>
				<el-form-item label="菜单权限">
					<el-checkbox v-model="qxCheckAll" @change="checkAll" class="tree_checkall" :indeterminate="isTreeCheckAll">全选</el-checkbox>
					<div class="tree_box">
						<el-tree :data="sysList" :props="props" show-checkbox @check="handleCheckChange" ref="tree" node-key="access"></el-tree>
					</div>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='closeMask'>取消</el-button>
				<el-button size="small" type="primary" class="title" @click="saveEdit(positionData.openType)">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
import { Loading } from 'element-ui';
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import Pagination from '../../components/pagination/pagination';
import ucMenu from '../../components/ucenterMenu/ucmenu';
export default {
	name: "ucenterPositionManage",
	components:{
		myHead,myFoot,ucMenu,Pagination
	},
	data() {
		return {
			isLoading: false,
			searchData:{},
			page_data: {
				limit: 10,
				page: 1,
				total:0,
			},
			positionData:{
				title:"",
				isShow:false,
				data:{
					name:"",
					status:1,
					description:"",
					access:[]
				},
				role_id:"",
			},
			list:[],
			sysList:[],
			qxCheckAll:false,
			props: {
				label: 'title',
				children: 'children'
			},
			isTreeCheckAll:false,
			treeNodesNum:0,
			ids:[],
			reflashMenu:false
		}
	},
	created() {
		this.getSysList()
		this.getList()
	},
	methods: {
		...mapActions({
			getBusinessRoleIndex:"ucenter/getBusinessRoleIndex",
			getBusinessRoleMenus:"ucenter/getBusinessRoleMenus",
			getBusinessRoleInfo:"ucenter/getBusinessRoleInfo",
			opBusinessRoleAdd:"ucenter/opBusinessRoleAdd",
			opBusinessRoleUpdate:"ucenter/opBusinessRoleUpdate",
			opBusinessRoleDel:"ucenter/opBusinessRoleDel",
			opBusinessRoleExt:"ucenter/opBusinessRoleExt",
			getUcenterMenu:"login/getUcenterMenu",
		}),
		positionOpen(type,id){//打开岗位弹窗
			this.positionData.isShow = true
			this.positionData.openType = type
			if(type==1){
				this.positionData.title = "新增岗位"
			}else if(type==2){
				this.positionData.title = "修改岗位"
				this.positionData.role_id = id
				this.getBusinessRoleInfo({data:{role_id:id},success:(res)=>{
					if(res.status==200){
						// console.log('getBusinessRoleInfo',res)
						this.positionData.data = res.data
						this.$nextTick(() => {
							const arr = []
							res.data.access.forEach(item => {
								arr.push(item)
							})
							if(arr.length==0){
								this.isTreeCheckAll = false
								this.qxCheckAll = false
							}else if(arr.length==this.treeNodesNum+1){
								this.qxCheckAll = true
								this.isTreeCheckAll = false
							}else{
								this.isTreeCheckAll = true
							}
							this.$refs.tree.setCheckedKeys(arr)
						})
					} else {
						this.$message({message: res.msg,type: 'error',});
					}
					this.isLoading = false
				}})
			}
		},
		positionDel(row){//岗位删除
			this.$confirm('确定操作吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let ids = row.role_id ? row.role_id : this.ids.join(',')
				this.opBusinessRoleDel({data:{role_id:ids},success:(res)=>{
					if(res.status==200){
						// console.log('del',res)
						this.$message({type: 'success',message: res.msg})
						this.getList()
					} else {
						this.$message({message: res.msg,type: 'error',});
					}
					this.isLoading = false
				}})
			}).catch(() => {})
		},
		getList(showLoading = true){//获取岗位列表
			if(showLoading) this.isLoading = true
			let param = {limit:this.page_data.limit,page:this.page_data.page}
			Object.assign(param, this.searchData)
			this.getBusinessRoleIndex({data:param,success:(res)=>{
				if(res.status==200){
					console.log('getBusinessRoleIndex',res)
					this.list = res.data.data
					this.page_data.total = res.data.total
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
				this.isLoading = false
			}})
		},
		getSysList(){//获取系统权限列表
			this.getBusinessRoleMenus({data:{},success:(res)=>{
				if(res.status==200){
					// console.log('getBusinessRoleMenus',res)
					let queryArr = []
					for(let i in res.menus){
						if(res.menus[i].children){
							for(let c in res.menus[i].children){
								queryArr.push(res.menus[i].children[c])
							}
						}
						queryArr.push(res.menus[i])
					}
					this.treeNodesNum = queryArr.length
					this.sysList = res.menus
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		handleCheckChange(curTag,wholeStatus) {//系统权限--单选
			// console.log('ccccccc',curTag,wholeStatus)
			let checkList = this.$refs.tree.getCheckedKeys()
			if(wholeStatus.halfCheckedKeys.length>0){
				checkList.push(wholeStatus.halfCheckedKeys)
			}
			if(wholeStatus.checkedNodes.length==0){
				this.isTreeCheckAll = false
				this.qxCheckAll = false
			}else if(wholeStatus.checkedNodes.length==this.treeNodesNum){
				this.qxCheckAll = true
				this.isTreeCheckAll = false
			}else{
				this.isTreeCheckAll = true
			}
			this.positionData.data.access = checkList
		},
		checkAll(e){//系统权限--全选
			this.qxCheckAll = e
			this.isTreeCheckAll = false
			if(this.qxCheckAll){
				this.$refs.tree.setCheckedNodes(this.sysList)
			}else{
				this.$refs.tree.setCheckedKeys([]);
			}
			this.positionData.data.access = this.$refs.tree.getCheckedKeys()
		},
		resetSearch(){//重置搜索
			this.searchData = {}
			this.getList()
		},
		closeMask(){//关闭弹窗
			this.positionData = this.$options.data().positionData
			this.qxCheckAll = false
			this.isTreeCheckAll = false
			this.$refs.tree.setCheckedKeys([]);
		},
		saveEdit(type){
			if(!this.positionData.data.name){
				this.$message({type: 'info',message: "请输入岗位名称！"})
				return false
			}
			if(this.positionData.data.access.length == 0){
				this.$message({type: 'info',message: "请至少勾选一项菜单权限！"})
				return false
			}
			let param = this.positionData.data
			if(type==1){//新增
				this.opBusinessRoleAdd({data:param,success:(res)=>{
					if(res.status==200){
						console.log('add',res)
						this.$message({type: 'success',message: res.msg})
						this.closeMask()
						this.searchData.page = 1
						this.getList()
					} else {
						this.$message({message: res.msg,type: 'error',});
					}
					this.isLoading = false
				}})
			}else if(type==2){//修改
				this.opBusinessRoleUpdate({data:param,success:(res)=>{
					if(res.status==200){
						console.log('update',res)
						this.$message({type: 'success',message: res.msg})
						this.closeMask()
						this.getList()
						this.getUCmenu()
					} else {
						this.$message({message: res.msg,type: 'error',});
					}
					this.isLoading = false
				}})
			}
		},
		positionStatus(row){//禁用启用
			let param = {
				role_id:row.role_id,
				status:row.status
			}
			this.opBusinessRoleExt({data:param,success:(res)=>{
				if(res.status==200){
					this.$message({type: 'success',message: res.msg})
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		selection(selection) {
			this.ids = selection.map(item => item.role_id)
		},
		getUCmenu(){
			this.getUcenterMenu({data:{},success:(res)=>{
				if(res.status==200){
					// console.log('getUCmenu',res)
					this.reflashMenu = true
					setTimeout(()=>{
						this.reflashMenu = false
					},1000)
					// this.employeeData.data = res.data
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
	}
}

</script>

<style scoped>
.wrap .wrap_body{display: flex;flex-direction: column;}
.center{display: flex;flex: 1;}
.ucenter_main{padding: 28px 0 40px;display: flex;justify-content: space-between;flex: 1;min-height: 500px;}
.right_con{width: 980px;background: #fff;}
.rc_table{width: 100%;margin-top: 20px}
.rc_main{padding: 36px 40px 46px;}
.select_wrap{margin-top: 24px;display: flex;flex-wrap: wrap;}
.select_wrap .mr{margin-right: 15px;}
.select_wrap .w290{width: 290px;margin-top: 14px;margin-right: 15px;position: relative;}
.select_wrap .w290:nth-child(3n){margin-right: 0;}
.el-cascader{line-height: 34px;}
.select_wrap .label_title{width: 76px;height: 100%;font-size: 12px;color: #999;display: flex;align-items: center;padding-left: 6px;}
.totals_btn{margin-top: 14px;display: flex;align-items: center;}
.tree_checkall{margin-top: 12px;}
.tree_box{padding: 10px;width: 100%;border: 1px solid #DCDFE6;border-radius: 4px;box-sizing: border-box;max-height: 250px;overflow: hidden auto;margin-top: 12px;}
.tree_box::-webkit-scrollbar{width:4px;}
.tree_box::-webkit-scrollbar-thumb{background: #DDDDDD;border-radius: 4px;}
.tree_box::-webkit-scrollbar-track{background: #F2F2F2;border-radius: 4px;}

</style>
<style>
.el-cascader{width: 100%;}
.select_wrap .el-button{margin-top: 14px;}
.select_wrap .el-input--prefix .el-input__inner,.select_wrap .el-cascader .el-input .el-input__inner{padding-left: 76px !important;height: 34px;line-height: 34px;font-size: 12px !important;}
.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus{border-color: var(--red);}
.el-select-dropdown__item.selected,.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{color: var(--red);}
.select_wrap .el-input--prefix .el-input__inner:focus{border-color: var(--red);}
.el-input__prefix, .el-input__suffix{display: flex;align-items: center;}
.el-button{color: #333;border-color: #ddd;}
.el-button--small{padding: 7px 15px;}
.el-checkbox{color: #666;display: flex;align-items: center;}
.el-checkbox__inner{border-color: #ddd;width: 16px;height: 16px;}
.el-checkbox__input{display: flex;}
.el-checkbox__label{font-size: 12px;line-height: 1;}
.el-checkbox__inner::after{height: 9px;left: 5px;}
.el-checkbox__input.is-indeterminate .el-checkbox__inner::before{height: 4px;}
.totals_btn .el-button--primary.is-plain{border-color: #fff;}

/*red border color*/
.el-button.is-plain:focus, .el-button.is-plain:hover ,.el-checkbox__inner:hover ,.el-checkbox__input.is-focus .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-pagination__jump .el-input__inner:focus,.el-input.is-focus .el-input__inner,.el-input .el-input__inner:focus,.el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner{border-color: var(--red);}

/*red color*/
.el-button.is-plain:focus, .el-button.is-plain:hover, .el-checkbox__input.is-checked+.el-checkbox__label, .el-pager li.active ,.el-pagination button:hover ,.el-pager li:hover{color: var(--red);}

/*red background color*/
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color: var(--red);}

.el-button:focus, .el-button:hover{border-color: var(--red);color: var(--red);background:#fff;}
.el-button--primary{background: var(--red) !important;color: #fff !important;border-color: #fff;}
.el-button--primary:focus, .el-button:hover{border-color: var(--red);}
.el-loading-spinner .el-loading-text{color:var(--red);}
.el-loading-spinner .path{stroke: var(--red);}
.rc_table.el-table th.el-table__cell>.cell{padding: 0 14px;}
</style>